import React from 'react';
import { Row, Col } from 'react-bootstrap';
import './App.css';
// import logo from './img/Logo.png';
// import logo2 from './img/logo2.png';
// import marcaBf from './img/brainyFun.png';
// import marcaBfpreschool from './img/PreschoolInc.png';
// import brainyFunOnLine from './img/BrainyFunOnLine.png';
import Routes from './Routes.js'
import Header from './components/header';
import Footer from './components/footer';

const App = () => {
  return (
    <div className="container-fluid">
      <div className="container">
        <Header />
        {/* <Row className="mt-3 align-items-center">
          <Col md={3} className="text-center">
            <img src={logo} alt="Logo Brainy Fun" className="logoHeader"></img>
          </Col>
          <Col className="text-center">
            <Col md={"auto"}>
              <img src={marcaBf} alt="marca Brainy Fun" className="marcaHeader"></img>
            </Col>
            <Col md={"auto"}>
              <img src={marcaBfpreschool} alt="marca Brainy Fun preschool" className="marcaHeaderPreschool"></img>
            </Col>
          </Col>
          <Col md={3} className="text-center">
            <a href="https://rocket.gvalor.co" target="_blank" rel="noopener noreferrer"><img src={brainyFunOnLine} alt="Brainy Fun OnLine" className="brainyFunOnLine"></img></a>
          </Col>
        </Row> */}
        <hr className="altColor"></hr>
        <Row className="altBakcground pt-3 pb-3 bordered">
          <Col className="text-center">
            <a 
              href='https://www.google.com/maps/place/Nido+Brainy+Fun+La+Molina/@-12.0780382,-76.919706,15z/data=!4m14!1m7!3m6!1s0x9105c6d51ecce1f9:0xb7301c09e2d942a2!2sNido+Brainy+Fun+La+Molina!8m2!3d-12.0780382!4d-76.919706!16s%2Fg%2F11c21hj19y!3m5!1s0x9105c6d51ecce1f9:0xb7301c09e2d942a2!8m2!3d-12.0780382!4d-76.919706!16s%2Fg%2F11c21hj19y'
              target='_blank'
              rel="noopener noreferrer"
            >
              <i 
              className="fas fa-map-marker-alt"
              >
                &nbsp;&nbsp;
              </i>
                Calle islas aleutianas 120. La Molina | &nbsp;
            </a>
          <a href="https://wa.me/+51934653328" target="_blank" rel="noopener noreferrer"><i className="fab fa-whatsapp">&nbsp;&nbsp;</i>+51 934 653 328 | &nbsp;</a>
          <a href="https://www.facebook.com/brainyfun.pe" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f">&nbsp;&nbsp;</i>/brainyfun.pe | &nbsp;</a>
          <a href="https://www.instagram.com/brainyfunpreschool" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram">&nbsp;&nbsp;</i>brainyfunpreschool &nbsp;</a>
          </Col>
        </Row>
        <hr></hr>
        
        <Routes />

        <hr></hr>
        {/* <Row>
          <Col className="align-self-center">
            <h5><i className="fas fa-map-marker-alt"></i><i> Calle islas aleutianas 120. La Molina.</i></h5>
            <h5><i className="fab fa-whatsapp"></i><i className="mb-2"> +51 934 653 328</i></h5>
          </Col>
          <Col className="text-right">
            <img src={logo2} alt="Footer Logo" className="footerLogo"></img>
          </Col>
        </Row> */}
        <Footer />
        {/* <hr className="altColor"></hr>
        <Row className="GVIS-Background pt-2 pb-2 mb-3 align-items-center bordered">
          <Col className="text-center">
            <i className="whiteText">© Grupo Educativo Valor - Todos los derechos reservados.</i>
          </Col>
        </Row> */}
      </div>
    </div>
  );
}

export default App;