import React from "react";
import { Col, Row } from "react-bootstrap";
import logoBFWeb from "../img/LOGOBFWeb.png"

const Header = () => {

    const logoImg = {
        float: "right",
        marginTop: "1rem",
    };

    return (
        <div>
            <Row>
                <Col className="txt-right">
                    <img 
                        src={logoBFWeb} 
                        alt="logo Brainy Fun"
                        style={logoImg}/>
                </Col>
            </Row>
        </div>
    )
};

export default Header;