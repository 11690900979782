import React, { useState } from "react";
import { Row, Col, Form, Button, Spinner, Alert } from "react-bootstrap";
import { BsRobot } from "react-icons/bs";
import axios from 'axios';

//require('dotenv').config();

const NotasViernes = () => {
    const [isIndividual, setIsIndividual] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [nombreEstudiante, setNombreEstudiante] = useState("");
    const [habilidadesEstudiante, setHabilidadesEstudiante] = useState("");
    const [actividades, setActividades] = useState("");
    const [modelResponse, setModelResponse] = useState("");

    const callCGPT = async () => {
        setIsLoading(true);
        setModelResponse("");
        setIsError(false);
        const heads = {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + process.env.REACT_APP_OPENAI_API_KEI
        };
        const apiBody = {
            model: "gpt-3.5-turbo",
            temperature: 0,
            messages: []
        };
        if (isIndividual) {
            apiBody.messages.push(
                {
                    'role': 'system', 
                    'content': `Eres una docente de nivel preescolar que trabaja en Brainy Fun Preschool Inc. En Lima Perú, 
                    tu trabajo es generar un breve mensaje comunicando a los papas del estudiante el trabajo realizado durante la semana, 
                    teniendo en cuenta sus aptitudes y habilidades, e incluyendo recomendaciones o estrategias 
                    de mejora, usando un lenguaje gentil y amigable. Debes comenzar el mensaje con: Dear Mom and Dad.`
                },
                {
                    'role': 'user', 
                    'content': `El estudiante se llama ${nombreEstudiante}, es ${habilidadesEstudiante}, durante la semana practico ${actividades}`
                }
            )
        } else {
            apiBody.messages.push(
                {
                    'role': 'system', 
                    'content': `Eres una docente de nivel preescolar que trabaja en Brainy Fun Preschool Inc. En Lima Perú, 
                    tu trabajo es generar un breve mensaje en español comunicando a los papas de los estudiantes las actividades realizadas durante la semana,
                    e incluyendo recomendaciones o estrategias relacionadas con dichas actividades, 
                    usando un lenguaje gentil y amigable. Debes comenzar el mensaje con: Dear Mom and Dad.`
                },
                {
                    'role': 'user', 
                    'content': actividades
                }
            )
        }
        console.log("Conectado con openAI");
        // console.log(apiBody);
        // console.log(heads);
        try {
            const response = await axios("https://api.openai.com/v1/chat/completions", {
                method: "post",
                headers: heads,
                //body: apiBody
                data: JSON.stringify(apiBody)
            });
            if(response.hasOwnProperty('data')){
                if(response.data.choices.length > 0){
                    setModelResponse(response.data.choices[0].message.content);
                } else {
                    const err = "1";
                    throw(err);
                }
            } else {
                const err = "0";
                throw(err);
            }
            setIsError (false);
        } catch (error) {
            switch (error) {
                case "0":
                    console.log("Error: sin respuesta desde openAI")
                    break;
                case "1":
                    console.log("Error: respuesta vacia desde openAI")
                    break;                
                default:
                    console.log("Error: conexión con openAI")
                    break;
            }
            setIsError(true);
        }  
        setIsLoading(false);
    };

    return (
        <div>
            <Row>
                <Col>
                    <Form.Check
                        inline
                        label="Nota general"
                        name="checksTipoNota"
                        type="radio"
                        id="general"
                        //value={!isIndividual}
                        onChange={() => setIsIndividual(!isIndividual)}
                        defaultChecked
                    />
                    <Form.Check
                        inline
                        label="Nota individual"
                        name="checksTipoNota"
                        type="radio"
                        id="individual"
                        //value={isIndividual}
                        onChange={() => setIsIndividual(!isIndividual)}
                    />
                </Col>
            </Row>
            <hr></hr>
            {isIndividual &&
                <>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>
                                    Nombre estudiante
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    disabled={isLoading}
                                    value={nombreEstudiante}
                                    onChange={(e) => setNombreEstudiante(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>
                                    Habilidades a resaltar
                                </Form.Label>
                                <Form.Control
                                    as="textarea"
                                    disabled={isLoading}
                                    rows={5}
                                    value={habilidadesEstudiante}
                                    onChange={(e) => setHabilidadesEstudiante(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </>
            }
            <Row>
                <Col>
                    <Form.Group>
                        <Form.Label>
                            Actividades a resaltar
                        </Form.Label>
                        <Form.Control
                            as="textarea"
                            disabled={isLoading}
                            rows={5}
                            value={actividades}
                            onChange={(e) => setActividades(e.target.value)}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col className="text-center">
                    <Button
                        variant="outline-primary"
                        disabled={isLoading}
                        onClick={() => callCGPT()}>
                        {isLoading ? 
                            <Spinner
                                as="span"
                                animation="grow" 
                                size="sm"
                            />
                        :
                            <BsRobot />
                        }{" Generar nota"}
                    </Button>
                </Col>
            </Row>
            <hr></hr>
            {isError && 
                <Row>
                    <Col>
                        <Alert variant="warning">Ups! Se presento un error, intentalo de nuevo más tarde.</Alert>
                    </Col>
                </Row>
            }
            <Row>
                <Col>
                    <Form.Control
                        as="textarea"
                        readOnly
                        rows={15}
                        value={modelResponse}
                        onChange={(e) => setModelResponse(e.target.value)}
                    />
                </Col>
            </Row>
        </div>
    )
};

export default NotasViernes;