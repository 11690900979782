import React from 'react';
import { Row, Col, Jumbotron } from 'react-bootstrap';
import { Zoom } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import imgSlider1 from '../img/Fondo1.png';
import imgSlider2 from '../img/Fondo2.png';
import imgSlider3 from '../img/Fondo3.png';
import matricula1 from '../img/matricula1.png';

const Main = () => {
    const slidersImg = [
        imgSlider1,
        imgSlider2,
        imgSlider3
    ];
    const zoomInProperties = {
        indicators: true,
        scale: 1.4,
        pauseOnHover: true,
        prevArrow: <div style={{width: "30px", marginRight: "-30px"}}></div>,
        nextArrow: <div style={{width: "30px", marginRight: "-30px"}}></div>
    }
    
    return (
        <div>
            <Row className="align-items-center" style={{top: "0", left:"80px", position:'fixed', zIndex:'999'}}>
                <Col className="text-center">
                    <a 
                        href="https://rocket.gvalor.co/matriculas?instId=3685be60-7c98-11eb-bf45-79dd1dfe71cc&fId=a59f9c00-a269-11ed-9f6a-bf673ded8bf1"
                        target="_blank"
                        rel="noopener noreferrer"
                    ><img className="btn-matriculas" style={{ width: "50%" }} src={matricula1} alt="matriculas abiertas"/></a>
                </Col>
            </Row>
            <Zoom {...zoomInProperties}>
            {slidersImg.map((each, index) => (
                <div key={index} style={{width: "100%"}}>
                    <img style={{ objectFit: "cover", width: "100%" }} src={each} alt="slider"/>
                </div>
            ))}
            </Zoom>
            <hr></hr>
            <Row>
                <Col className="text-center">
                    <h3 className="altColorText">En Brainy Fun fomentamos las habilidades sociales, comunicativas y cognitivas; 
                        así como las destrezas en el uso de la tecnología educativa. Herramientas escenciales 
                        para los niños de las nuevas generaciones.</h3>
                </Col>
            </Row>
            <hr className=""></hr>
            <Row>
                <Col className="text-center">
                    <h3 className="altColorText2">En Brainy Fun Encontrarás</h3>
                </Col>
            </Row>
            <Row className="justify-content-center mt-3">
                <Col sm={6} className="text-center whiteText">
                    <Jumbotron className="jumboAltColor">
                        <h1>Preschool!</h1>
                        <p>
                            Basados en el aprendizaje significativo por proyectos (BLP) con enfoque tecnológico / Bilingüe.
                        </p>
                        <p>
                            <i className="fas fa-rocket whiteFont"></i>
                        </p>
                    </Jumbotron>
                </Col>
                <Col sm={6} className="text-center whiteText">
                    <Jumbotron className="jumboAltColor2">
                        <h2>Estimulación creativa!</h2>
                        <p>
                            Para los más pequeños, de 6 a 24 meses, primeros movimientos!.
                        </p>
                        <p>
                            <i className="fas fa-baby-carriage whiteFont"></i>
                        </p>
                    </Jumbotron>
                </Col>
                <Col sm={6} className="text-center whiteText">
                    <Jumbotron className="jumboAltColor">
                        <h1>After School!</h1>
                        <p>
                            Aprestamiento, tutorias, refuerzos. Afianzamos y estimulamos las habilidades de tus hijos.
                        </p>
                        <p>
                            <i className="fas fa-paint-brush whiteFont"></i>
                        </p>
                    </Jumbotron>
                </Col>
            </Row>
        </div>
    );
}

export default Main;