import React from "react"
import { Route, Switch } from "react-router-dom";
import Main from "./containers/Main.js";
import PrivacyPolicy from "./containers/privacyPolicy";
import WhyUs from "./containers/why-us.js";
import NotasViernes from "./containers/notasViernes.js";

export default function Routes (){
    return (
        <Switch>
            <Route exact path="/">
                <Main />
            </Route>
            <Route exact path="/politica-de-privacidad">
                <PrivacyPolicy />
            </Route>
            <Route exact path="/why-us">
                <WhyUs />
            </Route>
            <Route exact path="/notas-viernes">
                <NotasViernes />
            </Route>
        </Switch>
    );
}