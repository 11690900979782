import React from "react";
import { Row, Col } from "react-bootstrap";

const Footer = () => {

    return (
        <div>
             <hr className="altColor"></hr>
            <Row className="GVIS-Background pt-2 pb-2 mb-3 align-items-center bordered">
                <Col className="text-center">
                    <i className="whiteText">© Grupo Educativo Valor - Todos los derechos reservados.</i>
                </Col>
            </Row>
        </div>
    )
};

export default Footer;